import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid gap-6 grid-cols-2" }
const _hoisted_2 = { class: "w-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_component_select_delivered_by = _resolveComponent("component-select-delivered-by")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, { label: "Nama Partner" }, {
        default: _withCtx(() => [
          _createVNode(_component_lp_input, {
            modelValue: _ctx.partnerName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.partnerName = $event)),
            disabled: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, { label: "Tipe User" }, {
        default: _withCtx(() => [
          _createVNode(_component_lp_input, {
            modelValue: _ctx.typeUser,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.typeUser = $event)),
            disabled: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, { label: "Kota Asal" }, {
        default: _withCtx(() => [
          _createVNode(_component_lp_input, {
            modelValue: _ctx.originCity,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.originCity = $event)),
            placeholder: "-",
            disabled: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, { label: "Tanggal Dibuat" }, {
        default: _withCtx(() => [
          _createVNode(_component_Datepicker, {
            class: "mt-1.5",
            disabled: "",
            modelValue: _ctx.form.dateCreated,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.dateCreated = $event)),
            placeholder: "Pilih atau masukkan No. seri kendaraan"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, { label: "Dikirim Oleh" }, {
        default: _withCtx(() => [
          _createVNode(_component_component_select_delivered_by, {
            class: "mt-1.5",
            modelValue: _ctx.form.deliveredBy,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.deliveredBy = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, { label: "Nama Kurir" }, {
        default: _withCtx(() => [
          _createVNode(_component_SelectSearch, {
            "is-loading": _ctx.loadingDriver,
            onFocus: _ctx.fetchDrivers,
            "onSubmit:driver": _ctx.onCreatedDriver,
            addDriver: true,
            serverSide: false,
            class: "mt-1.5",
            options: _ctx.driverOptions,
            keyName: "driverInfo",
            keyValue: "driverId",
            modelValue: _ctx.form.courier,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.courier = $event)),
            placeholder: "Pilih Kurir"
          }, null, 8, ["is-loading", "onFocus", "onSubmit:driver", "options", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        class: "col-span-2",
        label: "No. STT"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_lp_input, {
            ref: "inputStt",
            onEnter: _ctx.validateSttNoToUpdate,
            sizeInput: "24px",
            labelStacked: "Contoh : 99LP1234567890123/88-22-12345678",
            modelValue: _ctx.scanSttNumber,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.scanSttNumber = $event)),
            placeholder: "Masukan No. STT atau Scan Barcode/QR Code",
            bold: !!_ctx.scanSttNumber,
            error: !_ctx.isPaid,
            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setIsPaid(true))),
            errorCaption: 
          !_ctx.isPaid
            ? 'Terjadi kelebihan berat sehingga status tidak dapat diupdate ke DEL'
            : ''
        
          }, null, 8, ["onEnter", "modelValue", "bold", "error", "errorCaption"])
        ]),
        _: 1
      }),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_lp_button, {
          onClick: _ctx.validateSttNoToUpdate,
          customClass: "px-12 py-2.5 w-full",
          title: "Tambah",
          textColor: "white",
          disabled: _ctx.isDisabledAdd
        }, null, 8, ["onClick", "disabled"])
      ])
    ]),
    _createVNode(_component_Notification, {
      modelValue: _ctx.notification,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.notification = $event)),
      onClose: _ctx.onCloseNotification,
      data: _ctx.sttNotification,
      status: _ctx.isUnpaidNotification ? 'error' : 'success'
    }, null, 8, ["modelValue", "onClose", "data", "status"])
  ], 64))
}