import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  SttDetail,
  SttValidation,
  UpdateDelEntities,
  PrintData
} from "@/domain/entities/UpdateDelivery";
import { container } from "tsyringe";
import { UpdateDeliveryPresenter } from "@/app/ui/presenters/UpdateDeliveryPresenter";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  CLIENT_ERROR,
  parsingErrorResponse,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { CreateUpdateDelivery } from "@/data/payload/api/UpdateDeliveryReqeust";
import { Pagination } from "@/domain/entities/Pagination";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";

export interface UpdateDeliveryState {
  sttValidation: SttValidation;
  form: any;
  loadingPrintData: boolean;
  printDataDelivery: PrintData;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "incoming-update-delivery"
})
class UpdateDeliveryStore extends VuexModule implements UpdateDeliveryState {
  public sttValidation = new SttValidation();
  public isLoading = false;
  public notification = false;
  public sttNotification: any = {};
  public errorType = "";
  public listData = new UpdateDelEntities(new Pagination(1, 10), []);
  public filter = {
    startDate: "",
    endDate: "",
    search: ""
  };
  public loadingPrintData = false;
  public printDataDelivery = new PrintData();
  public isPaid = true;
  public isUnpaidNotification = false;

  @Action
  async getList(params: {
    page: number;
    limit: number;
    search: string;
    startDate: string;
    endDate: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(UpdateDeliveryPresenter);
    return presenter
      .getUpdateDelList(
        params.page,
        params.limit,
        params.search,
        params.startDate,
        params.endDate
      )
      .then((res: UpdateDelEntities) => {
        this.setListData(res);
        this.setErrorType("");
      })
      .catch(err => {
        this.setErrorType(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public fetchList() {
    this.getList({
      page: this.listData.pagination.page,
      limit: this.listData.pagination.limit,
      search: this.filter.search,
      startDate: this.filter.startDate
        ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
        : "",
      endDate: this.filter.endDate
        ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
        : ""
    });
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchList();
  }

  @Mutation
  private async setFirstPage() {
    this.listData = new UpdateDelEntities(new Pagination(1, 10), []);
  }

  @Action
  public async getSttValidationUpdateDelivery(params: {
    sttNo: string;
    callback: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    this.setNotification(false);
    const presenter = container.resolve(UpdateDeliveryPresenter);
    return presenter
      .getSttValidationUpdateDelivery(params.sttNo)
      .then((res: SttValidation) => {
        if (!res.isAllowUpdateStatus) {
          playNotification("error");
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "Scan Gagal !",
              message: res.errorMessage,
              onClose: params.callback
            })
          );
          return res;
        }

        this.setPaid(res.isPaid);
        if (this.isPaid) {
          this.addSttListNoToUpdate(res.sttDetail);
          this.setUnpaidNotification(!res.isPaid);

          setTimeout(() => {
            this.setNotification(false);
            this.setSttNotification({});
          }, 15 * 1000);
        } else {
          this.setUnpaidNotification(!res.isPaid);
          playNotification("error");
          this.setNotification(true);
        }

        this.setSttNotification({
          sttElexysNumber: res.sttDetail.sttNoElexys,
          sttNumber: res.sttDetail.sttNo,
          destinationCity: `${res.sttDetail.sttDestinationCityCode} - ${res.sttDetail.sttDestinationCityName}`,
          product: res.sttDetail.sttProductType,
          woodPacking: res.sttDetail.sttIsWoodPacking ? "Ya" : "Tidak",
          commodity: res.sttDetail.sttCommodityName,
          sttTotalPiece: res.sttDetail.sttTotalPiece,
          codAmount: res.sttDetail.sttCodAmount
        });
        return true;
      })
      .catch(err => {
        const errorMessage = err.response.data.message.id;
        const isNeedToHal = errorMessage === "STT tidak dapat diupdate delivery karena perlu di HAL";
        const isNeedToRts = errorMessage === "STT tidak dapat diupdate delivery karena perlu di RTS";
        const isJumbopackH2H = errorMessage === "STT gagal discan karena paket akan diberi status POD setelah diambil oleh penerima.";


        playNotification("error");
        if (isNeedToHal || isNeedToRts) {
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "STT tidak dapat di update",
              message: errorMessage,
              onClose: params.callback
            })
          );
        } 
        else if (isJumbopackH2H) {
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: CLIENT_ERROR,
              title: "Paket akan diambil penerima",
              message: errorMessage,
              onClose: params.callback,
              buttonSuccessText: "Oke, mengerti"
            })
          );
        }
        else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Scan Gagal !", () => {
              this.getSttValidationUpdateDelivery(params);
            })
          );
        }
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async createUpdateDelivery(params: CreateUpdateDelivery) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(UpdateDeliveryPresenter);
    return presenter
      .createUpdateDelivery(params)
      .then(res => res)
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembaruan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.createUpdateDelivery(params);
          })
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  setNotification(bool: boolean) {
    this.notification = bool;
  }
  @Mutation
  setSttNotification(data: any) {
    this.sttNotification = data;
  }

  public form: any = {
    dateCreated: new Date(),
    deliveredBy: "",
    courier: "",
    sttNoToUpdate: "",
    listSttNoToUpdate: []
  };
  @Action
  resetForm() {
    this.setForm({
      dateCreated: new Date(),
      deliveredBy: "",
      courier: "",
      sttNoToUpdate: "",
      listSttNoToUpdate: []
    });
  }
  @Mutation
  setForm(data: any) {
    this.form = data;
  }

  @Action
  addSttListNoToUpdate(newStt: SttDetail) {
    const indexCurrentSttNumber = this.form.listSttNoToUpdate.findIndex(
      (data: SttDetail) => {
        return data.sttNo === newStt.sttNo;
      }
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.setNotification(false);
      MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          type: CLIENT_ERROR,
          title: "Scan Gagal !",
          message: "No. STT sudah di input"
        })
      );
      return;
    }
    playNotification("success");
    this.setNotification(true);
    this.pushSttListNoToUpdate(newStt);
  }
  @Action
  async removeSttListNoToUpdate(sttNo: string) {
    sttNo = sttNo.toUpperCase();
    const indexCurrentSttNumber = this.form.listSttNoToUpdate.findIndex(
      (data: SttDetail) => {
        return data.sttNo === sttNo || data.sttNoElexys === sttNo;
      }
    );
    if (indexCurrentSttNumber < 0) {
      return false;
    }
    this.spliceSttListNoToUpdate(indexCurrentSttNumber);
    return true;
  }

  @Action
  public printDelivery(params: { id: number }) {
    this.setLoadingPrintDelivery(true);
    const presenter = container.resolve(UpdateDeliveryPresenter);
    return presenter
      .printDelivery(params.id)
      .then((res: PrintData) => {
        this.setPrintDelivery(res);
        return true;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        this.setLoadingPrintDelivery(false);
      });
  }

  @Mutation
  public setLoadingPrintDelivery(val: boolean) {
    this.loadingPrintData = val;
  }

  @Mutation
  public setPaid(val: boolean) {
    this.isPaid = val;
  }

  @Mutation
  public setUnpaidNotification(val: boolean) {
    this.isUnpaidNotification = val;
  }

  @Mutation
  public setPrintDelivery(data: PrintData) {
    this.printDataDelivery = data;
  }

  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("update-delivery") || "[]"
  );
  @Mutation
  pushSttListNoToUpdate(newStt: SttDetail) {
    this.form.listSttNoToUpdate.push(newStt);
    storeDatatoLocalStorage("update-delivery", this.form.listSttNoToUpdate);
  }
  @Mutation
  spliceSttListNoToUpdate(index: number) {
    this.form.listSttNoToUpdate.splice(index, 1);
    storeDatatoLocalStorage("update-delivery", this.form.listSttNoToUpdate);
  }

  @Mutation
  setSttFromLocalStorage(data: any) {
    this.form.listSttNoToUpdate = data;
  }

  @Mutation
  setListData(data: UpdateDelEntities) {
    this.listData = data;
  }

  @Mutation
  setErrorType(value: string) {
    this.errorType = value;
  }

  @Mutation
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }
}

export const UpdateDeliveryController = getModule(UpdateDeliveryStore);
