
import { Vue, Options } from "vue-class-component";
import ComponentSelectDeliveredBy from "@/app/ui/views/incoming-process/delivery/components/component-select-delivered-by.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { UpdateDeliveryController } from "@/app/ui/controllers/UpdateDeliveryController";
import { capitalize } from "vue";
import { PickupManifestController } from "@/app/ui/controllers/PickupManifestController";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { SttDetail } from "@/domain/entities/UpdateDelivery";

@Options({
  components: {
    ComponentSelectDeliveredBy
  }
})
export default class UpdateDeliveryFormSection extends Vue {
  mounted() {
    AccountController.checkAccountLocation();
  }
  // get data for form
  get dataProfile() {
    return AccountController.accountData;
  }
  get partnerName() {
    return this.dataProfile.account_type_detail.company_name;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return capitalize(this.dataProfile.account_type_detail.type);
  }
  get originCity() {
    return `${this.dataProfile.account_location.city_code} - ${this.dataProfile.account_location.city_name}`;
  }

  // driver
  loadingDriver = true;
  get driverOptions() {
    return PickupManifestController.driverList;
  }
  onCreatedDriver() {
    this.fetchDrivers();
  }
  async fetchDrivers() {
    this.loadingDriver = true;
    await PickupManifestController.getDrivers();
    this.loadingDriver = false;
  }

  // form
  get form() {
    return UpdateDeliveryController.form;
  }
  get isDisabledAdd() {
    return (
      !this.form.deliveredBy ||
      !this.form.courier ||
      !this.scanSttNumber ||
      this.totalStt >= 250
    );
  }

  // get data needed
  get totalStt() {
    return UpdateDeliveryController.form.listSttNoToUpdate.length;
  }

  get sttDetail() {
    return TrackingController.trackingSttData.sttDetail;
  }

  get listStt(): SttDetail[] {
    return UpdateDeliveryController.form.listSttNoToUpdate;
  }

  // stt number to update
  errorSttNoToUpdate = "";
  scanSttNumber = "";
  async validateSttNoToUpdate() {
    this.form.sttNoToUpdate = this.scanSttNumber.trim();
    if (
      !this.form.sttNoToUpdate ||
      !this.form.deliveredBy ||
      !this.form.courier
    ) {
      return;
    }
    const isCheckTrackingSttFirst = !this.checkSttorBagFormatNumber(
      this.form.sttNoToUpdate.trim()
    ); // this is for checking if it need to use tracking stt endpoint or not
    const inputSttByUser = this.form.sttNoToUpdate;
    let generatedStt = this.scanSttNumber.trim();
    if (isCheckTrackingSttFirst) {
      await TrackingController.trackStt({
        sttNo: this.form.sttNoToUpdate,
        isGetSla: false,
        isSecondCall: false,
        cache: true
      }).then(isSuccess => {
        if (isSuccess) {
          this.form.sttNoToUpdate = this.sttDetail.sttNo;
          generatedStt = this.sttDetail.sttNo;
        }
      });
    }

    const splitVal = this.form.sttNoToUpdate.split("#");
    if (splitVal.length > 0) this.form.sttNoToUpdate = splitVal[0];
    await UpdateDeliveryController.getSttValidationUpdateDelivery({
      sttNo: this.form.sttNoToUpdate,
      callback: this.reFocusStt
    }).then(() => {
      if (isCheckTrackingSttFirst) {
        this.listStt.forEach(item => {
          if (item.sttNo.toLowerCase() == generatedStt.toLowerCase()) {
            item.sttNoRefExternal = inputSttByUser.trim();
          }
          return item;
        });
      }
    });
    this.form.sttNoToUpdate = "";
    this.scanSttNumber = "";
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt?.focus();
  }

  checkSttorBagFormatNumber(value: string) {
    const sttPatern = /^\d{2}[a-z]{2}/; // check for stt number
    const bagPattern = /^[a-z]{3}-\d+$/; // check for bag number
    return sttPatern.test(value.toLocaleLowerCase()) || bagPattern.test(value.toLocaleLowerCase());
  }

  reFocusStt() {
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt?.focus();
  }

  get isPaid() {
    return UpdateDeliveryController.isPaid;
  }
  setIsPaid(val: boolean) {
    UpdateDeliveryController.setPaid(val);
  }
  get isUnpaidNotification() {
    return UpdateDeliveryController.isUnpaidNotification;
  }

  // notification
  get notification() {
    return UpdateDeliveryController.notification;
  }
  onCloseNotification() {
    UpdateDeliveryController.setNotification(false);
    UpdateDeliveryController.setUnpaidNotification(false);
  }
  get sttNotification() {
    return UpdateDeliveryController.sttNotification;
  }
}
